// Strategies Design System CORE

// _core contains only mixin and variable definitions (no element or class definitions) so it can be included in multiple files; best to use just this + fonts when retrofitting an existing project

// Strategies Design System FONTS

// _fonts contains only font imports so it can be included in multiple files; best to use just this + core when retrofitting an existing project

// FONT IMPORTS

@import url("https://use.typekit.net/pem1hfh.css"); // Typekit import for Korolev
@import url("https://fast.fonts.net/cssapi/22c45dc8-b267-43c6-897c-ddf64b269121.css"); // Fonts.com import for Cheltenham

// MISC

@mixin user-select-none() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}



// MEDIA QUERIES

// Extra small devices (portrait phones, less than 576px)

// Small devices (landscape phones, 576px and up)
@mixin sm {
  @media (min-width: 576px) { @content; };
}

// Medium devices (tablets, 768px and up)
@mixin md {
  @media (min-width: 768px) { @content; };
}

// Large devices (desktops, 992px and up)
@mixin lg {
  @media (min-width: 992px) { @content; };
}

// Extra large devices (large desktops, 1200px and up)
@mixin xl {
  @media (min-width: 1200px) { @content; };
}



// COLOR PALETTE

// neutrals
$dk-grey: #3a3a3a;
  $dk-grey-hover: #292929;
  $dk-grey-focus: #000000;

$md-dk-grey: #727272;
  $md-dk-grey-hover: #696868;
  $md-dk-grey-focus: #595858;

$md-lt-grey: #aaaaaa;
  $md-lt-grey-hover: #9E9E9E;
  $md-lt-grey-focus: #949494;

$lt-grey: #d9d9d9;
  $lt-grey-hover: #D1CFCF;
  $lt-grey-focus: #C4C4C4;

$wash: #ebebeb;
  $wash-hover: #E3E3E3;
  $wash-focus: #d9d9d9;

$white: #ffffff;
  $white-hover: #f8f8f9;
  $white-focus: #f4f5f5;

// blues
$bold-blue: #441cc1;
  $bold-blue-hover: #4516AB;
  $bold-blue-focus: #3B1391;

$bright-blue: #607cff;
  $bright-blue-hover: #5771E6;
  $bright-blue-focus: #4E65CC;

$muted-blue: #8c99c1;
  $muted-blue-hover: #848DB5;
  $muted-blue-focus: #7B86A8;

$light-blue: #bfc9d8;
  $light-blue-hover: #B6BECF;
  $light-blue-focus: #ADB7C9;

// purples

$bold-purple: #720087;
  $bold-purple-hover: #5D006E;
  $bold-purple-focus: #480054;

$bright-purple: #9e5da5;
  $bright-purple-hover: #864F8C;
  $bright-purple-focus: #6E4073;

$muted-purple: #a88cad;
  $muted-purple-hover: #99809C;
  $muted-purple-focus: #88718A;

$light-purple: #d1c6d8;
  $light-purple-hover: #C4B7C9;
  $light-purple-focus: #B6A8BD;

// reds

$bold-red: #87002d;
  $bold-red-hover: #6E0025;
  $bold-red-focus: #54001C;

$bright-red: #ff002d;
  $bright-red-hover: #CC0025;
  $bright-red-focus: #B30021;

$muted-red: #b78e8e;
  $muted-red-hover: #A68280;
  $muted-red-focus: #967472;

$light-red: #ddbfbf;
  $light-red-hover: #D1B3B2;
  $light-red-focus: #C7A5A3;

// oranges

$bold-orange: #915b00;
  $bold-orange-hover: #784C00;
  $bold-orange-focus: #5E3C00;

$bright-orange: #ff7700;
  $bright-orange-hover: #E66B00;
  $bright-orange-focus: #CC5F00;

$muted-orange: #ad9b7f;
  $muted-orange-hover: #998B6D;
  $muted-orange-focus: #8C8065;

$light-orange: #d8ccaf;
  $light-orange-hover: #C9C0A3;
  $light-orange-focus: #BFB595;

// yellows

$bold-yellow: #5e5600;
  $bold-yellow-hover: #4F4E00;
  $bold-yellow-focus: #3F4200;

$bright-yellow: #d8a300;
  $bright-yellow-hover: #BF8F00;
  $bright-yellow-focus: #A67C00;

$muted-yellow: #999e72;
  $muted-yellow-hover: #878F63;
  $muted-yellow-focus: #79805B;

$light-yellow: #c6cea5;
  $light-yellow-hover: #B5BF97;
  $light-yellow-focus: #A4AD87;

$light-yellow-alt: #e6c370; // not Sasaki brand, but brand yellows are not great
  $light-yellow-alt-hover: #D6BA65;
  $light-yellow-alt-focus: #C2AF59;

$light-yellow-alt2: #F2E1B8; // not Sasaki brand, but brand yellows are not great
$light-yellow-alt2-hover: #E0D1A4;
$light-yellow-alt2-focus: #D9C793;

// greens

$bold-green: #005b2d;
  $bold-green-hover: #014D2A;
  $bold-green-focus: #004224;

$bright-green: #89ba00;
  $bright-green-hover: #76A100;
  $bright-green-focus: #638700;

$muted-green: #7fad96;
  $muted-green-hover: #729E8A;
  $muted-green-focus: #69917F;

$light-green: #bad8c9;
  $light-green-hover: #ADC9BD;
  $light-green-focus: #9FBDAF;

// placeholder for accent color
$primary: $bold-blue;
$primary-hover: $bold-blue-hover;
$primary-focus: $bold-blue-focus;

$secondary: $muted-green;
$secondary-hover: $muted-green-hover;
$secondary-focus: $muted-green-focus;

$nd: $dk-grey;
$nd-hover: $dk-grey-hover;
$nd-focus: $dk-grey-focus;

$nl: $wash;
$nl-hover: $wash-hover;
$nl-focus: $wash-focus;

$success: #4FB948;
$warning: #FECE07;
$error: #ED1C35;




// SHADOWS

@mixin shadow {
  box-shadow: 0 0 5px rgba(58,58,58,0.2);
}

@mixin shadow-hover {
  box-shadow: 0 0 7px rgba(58,58,58,0.2);
}

@mixin shadow-focus {
  box-shadow: 0 0 10px rgba(58,58,58,0.2);
}



// TYPOGRAPHY

$Korolev: 'Inter', Lato, Open Sans, sans-serif;
$Cheltenham: "ITC Cheltenham W01", Georgia, serif;
$OpenSans: 'Inter', Calibri, Arial, sans-serif;
$FontAwesome: "Font Awesome 5 Free";

@mixin h1 {
  font-size: 3.6rem;
  font-family: $Cheltenham;
  font-weight: normal;
  margin: 0;

  @include md {
    font-size: 4.8rem;
  }
}

@mixin h2 {
  font-size: 2.4rem;
  font-family: $Korolev;
  font-weight: 700;
  margin: 0;
  @include md {
    font-size: 3.6rem;
  }
}

@mixin h3 {
  font-size: 2rem;
  font-family: $Korolev;
  font-weight: 700;
  margin: 0;
  @include md {
    font-size: 2.4rem;
  }
}

@mixin h4 {
  font-size: 1.6rem;
  font-family: $Korolev;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;

  @include md {
    font-size: 1.8rem;
  }
}

@mixin h5 {
  font-size: 1.2rem;
  font-family: $Korolev;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;

  @include md {
    font-size: 1.4rem;
  }
}

@mixin h6 {
  font-size: 1rem;
  font-family: $Korolev;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;

  @include md {
    font-size: 1rem;
  }
}


@mixin p-lg {
  font-family: $OpenSans;
  font-weight: normal;
  font-size: 1.8rem;
}

@mixin p-md {
  font-family: $OpenSans;
  font-weight: normal;
  font-size: 1.4rem;
}

@mixin p-sm {
  font-family: $OpenSans;
  font-weight: normal;
  font-size: 1rem;
}

$br: 3px;

@mixin ss-button {
  @include h4;
  border-radius: $br;
  margin: 0;
  padding: 0 15px;
  border: none;
  height: 40px;
  transition: background-color 0.15s ease-in-out;
  cursor: pointer;
  min-width: 130px;
  background-color: white;
  color: white;

  &:focus, &:active {
    outline: 0;
  }

  &.block {
    width: 100%;
    max-width: 250px;
  }

  &.primary {
    background-color: $primary;
    color: white;

    &:hover {
      background-color: $primary-hover;
    }
    &:focus, &:active {
      background-color: $primary-focus;
    }
  }

  &.secondary {
    background-color: $secondary;
    color: white;

    &:hover {
      background-color: $secondary-hover;
    }
    &:focus, &:active {
      background-color: $secondary-focus;
    }
  }
  &.nl {
    background-color: $nl;
    color: $dk-grey;

    &:hover {
      background-color: $nl-hover;
    }
    &:focus, &:active {
      background-color: $nl-focus;
    }
  }
  &.nd {
    background-color: $nd;
    color: white;

    &:hover {
      background-color: $nd-hover;
    }
    &:focus, &:active {
      background-color: $nd-focus;
    }
  }
}

p, ul, ol { // default (medium) body text
  @include p-md;
}

p.p-lg { // large body text
   @include p-lg;
 }

p.p-md { // medium body text
  @include p-md;
}

p.p-sm { // small body text
  @include p-sm;
}

ul, ol {
  -webkit-padding-start: 0;
}

li {
  //margin-left: 1.5rem;
}

a {
  border-bottom: 1px dotted;
  text-decoration: none;
  transition: color 0.15s ease-in-out;

  &:hover {
    text-decoration: none;
    color: $primary-hover;
  }
  &:active, &:focus {
    text-decoration: none;
    color: $primary-focus;
  }
}

.ReactTable {
  border: none;

  @include p-md;

  .rt-tr-group {
    border-bottom: 1px solid $wash;

    .rt-td {
      border-right: none;
    }
  }

  &.-striped {

    .rt-tr {
      &.-odd {
        background: $wash;
        border-radius: $br;

        &:not(.-padRow):hover {
          background: $wash;

        }

        &:active, &:focus {
          background: $wash;
        }
      }
      &.-even {
        background: white;

        &:not(.-padRow):hover {
          background: white;

        }

        &:active, &:focus {
          background: white;
        }
      }
    }
  }

  .rt-thead {
    @include h5;
    background: white;
    color: $dk-grey;

    .rt-tr {
      text-align: left;

      .rt-th {
        border-right: none;

        &.-sort-asc {
          box-shadow: inset 0 2px 0 0 $dk-grey;
        }
        &.-sort-desc {
          box-shadow: inset 0 -2px 0 0 $dk-grey;
        }

      }
    }
  }

  .rt-thead.-headerGroups {
    background: white;
    border-bottom: 1px solid $wash;
  }
  .rt-thead.-header{
    box-shadow: none;
    border-bottom: 1px solid $md-lt-grey;

    .rt-tr {
      text-transform: none;
    }

  }
  .-pagination {
    box-shadow: none;
    border-top: 1px solid $md-lt-grey;
    padding: 10px 0;
  }
  .-loading {
    background: auto;
  }

  .-previous .-btn {
    @include ss-button;
    text-align: left;
    padding: 0 20px 0 15px;
    background-color: $nl;

    &:before {
      font-weight: 900;
      content: "\e9eb";
      margin-right: 10px;
      font-size: 1.25rem;
      margin: 0;



    }
  }

  .-next .-btn {
    @include ss-button;
    text-align: right;
    padding: 0 15px 0 20px;
    background-color: $nl;
    color: $dk-grey;

    &:hover {
      background-color: $nl-hover;
    }
    &:focus, &:active {
      background-color: $nl-focus;
    }

    &:after {
      font-weight: 900;
      content: "\e9ec";
      margin-left: 10px;
      font-size: 1.25rem;
    }
  }

  .-center {
    justify-content: center !important;
  }

  .-previous, .-next {
    flex: none !important;



    .-btn {
      min-width: unset;
      color: $dk-grey;

      &[disabled] {
        opacity: 1;
        background: white;
        // border: 1px solid $wash;
        border-radius: $br;
        color: $wash;
      }

      &:not([disabled]):hover {
        background-color: $nl-hover;
        color: $dk-grey;
      }
      &:focus, &:active {
        background-color: $nl-focus;
        color: $dk-grey;
      }


    }

  }
  select {
    @include p-md;
    border-radius: 3px;
    padding: 3px;
  }
}

a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: unset;
  text-decoration: none;
}



// ICONS



// BUTTONS

.ss-button {

  // default

  @include ss-button;

  // sizes

  &.small {
    font-size: 0.85rem;
  }

  &.large {
    font-size: 1.15rem;
  }

  &.block {
    width: 100%;
  }

  &.sq {
    width: 40px;
    height: 40px;
    background: white;
    @include shadow;
    min-width: 0;
    margin: 0;
    font-size: 2rem;
    color: $nd;
    padding: 0;
    border: 1px solid white;

    &:hover {
      color: $primary;
    }

    &:focus {
      color: $primary;
      //background: $wash;
    }
  }

  // all colors

  // NEUTRALS

  &.dk-grey {
    background: $dk-grey;

    &:hover {
      background: $dk-grey-hover;
    }
    &:focus {
      background: $dk-grey-focus;
    }
  }

  &.md-dk-grey {
    background: $md-dk-grey;

    &:hover {
      background: $md-dk-grey-hover;
    }
    &:focus {
      background: $md-dk-grey-focus;
    }
  }

  &.md-lt-grey {
    background: $md-lt-grey;

    &:hover {
      background: $md-lt-grey-hover;
    }
    &:focus {
      background: $md-lt-grey-focus;
    }
  }

  &.lt-grey {
    background: $lt-grey;
    color: $dk-grey;

    &:hover {
      background: $lt-grey-hover;
    }
    &:focus {
      background: $lt-grey-focus;
    }
  }

  &.white {
    background: white;
    color: $dk-grey;

    &:hover {
      background: $wash;
    }
    &:focus {
      background: $lt-grey;
    }
  }

  // BLUES

  &.bold-blue {
    background: $bold-blue;

    &:hover {
      background: $bold-blue-hover;
    }
    &:focus {
      background: $bold-blue-focus;
    }
  }
  &.bright-blue {
    background: $bright-blue;

    &:hover {
      background: $bright-blue-hover;
    }
    &:focus {
      background: $bright-blue-focus;
    }
  }
  &.muted-blue {
    background: $muted-blue;

    &:hover {
      background: $muted-blue-hover;
    }
    &:focus {
      background: $muted-blue-focus;
    }
  }
  &.light-blue {
    background: $light-blue;
    color: $dk-grey;

    &:hover {
      background: $light-blue-hover;
    }
    &:focus {
      background: $light-blue-focus;
    }
  }

  // purples

  &.bold-purple {
    background: $bold-purple;

    &:hover {
      background: $bold-purple-hover;
    }
    &:focus {
      background: $bold-purple-focus;
    }
  }
  &.bright-purple {
    background: $bright-purple;

    &:hover {
      background: $bright-purple-hover;
    }
    &:focus {
      background: $bright-purple-focus;
    }
  }
  &.muted-purple {
    background: $muted-purple;

    &:hover {
      background: $muted-purple-hover;
    }
    &:focus {
      background: $muted-purple-focus;
    }
  }
  &.light-purple {
    background: $light-purple;
    color: $dk-grey;

    &:hover {
      background: $light-purple-hover;
    }
    &:focus {
      background: $light-purple-focus;
    }
  }

  // reds

  &.bold-red {
    background: $bold-red;

    &:hover {
      background: $bold-red-hover;
    }
    &:focus {
      background: $bold-red-focus;
    }
  }
  &.bright-red {
    background: $bright-red;

    &:hover {
      background: $bright-red-hover;
    }
    &:focus {
      background: $bright-red-focus;
    }
  }
  &.muted-red {
    background: $muted-red;

    &:hover {
      background: $muted-red-hover;
    }
    &:focus {
      background: $muted-red-focus;
    }
  }
  &.light-red {
    background: $light-red;
    color: $dk-grey;

    &:hover {
      background: $light-red-hover;
    }
    &:focus {
      background: $light-red-focus;
    }
  }

  // oranges

  &.bold-orange {
    background: $bold-orange;

    &:hover {
      background: $bold-orange-hover;
    }
    &:focus {
      background: $bold-orange-focus;
    }
  }
  &.bright-orange {
    background: $bright-orange;

    &:hover {
      background: $bright-orange-hover;
    }
    &:focus {
      background: $bright-orange-focus;
    }
  }
  &.muted-orange {
    background: $muted-orange;

    &:hover {
      background: $muted-orange-hover;
    }
    &:focus {
      background: $muted-orange-focus;
    }
  }
  &.light-orange {
    background: $light-orange;
    color: $dk-grey;

    &:hover {
      background: $light-orange-hover;
    }
    &:focus {
      background: $light-orange-focus;
    }
  }

  // yellows

  &.bold-yellow {
    background: $bold-yellow;

    &:hover {
      background: $bold-yellow-hover;
    }
    &:focus {
      background: $bold-yellow-focus;
    }
  }
  &.bright-yellow {
    background: $bright-yellow;

    &:hover {
      background: $bright-yellow-hover;
    }

    &:focus {
      background: $bright-yellow-focus;
    }
  }
  &.muted-yellow {
    background: $muted-yellow;

    &:hover {
      background: $muted-yellow-hover;
    }
    &:focus {
      background: $muted-yellow-focus;
    }
  }
  &.light-yellow {
    background: $light-yellow;
    color: $dk-grey;

    &:hover {
      background: $light-yellow-hover;
    }
    &:focus {
      background: $light-yellow-focus;
    }
  }

  // greens

  &.bold-green {
    background: $bold-green;

    &:hover {
      background: $bold-green-hover;
    }
    &:focus {
      background: $bold-green-focus;
    }
  }
  &.bright-green {
    background: $bright-green;

    &:hover {
      background-color: $bright-green-hover;
    }

    &:focus {
      background-color: $bright-green-focus;
    }
  }
  &.muted-green {
    background: $muted-green;

    &:hover {
      background: $muted-green-hover;
    }
    &:focus {
      background: $muted-green-focus;
    }
  }
  &.light-green {
    background: $light-green;
    color: $dk-grey;

    &:hover {
      background: $light-green-hover;
    }
    &:focus {
      background: $light-green-focus;
    }
  }

}

//TOOLBARS

.hz-tb {
  display: inline-flex;
  border-radius: $br;
  @include shadow;

  .sq {
    box-shadow: none;

    &:first-child {
      width: 45px;
      padding-left: 5px;
    }

    &:last-child {
      width: 45px;
      padding-right: 5px;
    }
  }
}

.vt-tb {
  display: inline-flex;
  flex-direction: column;
  border-radius: $br;
  @include shadow;

  .sq {
    box-shadow: none;

    &:first-child {
      height: 45px;
      padding-top: 5px;
    }

    &:last-child {
      height: 45px;
      padding-bottom: 5px;
    }
  }
}

// LEAFLET

.leaflet-popup {
  .leaflet-popup-content-wrapper {
     border-radius: $br;
    @include shadow;

     .leaflet-popup-content {
       margin: 10px 20px 10px 10px;
       @include p-md;
     }
   }

  a.leaflet-popup-close-button {
    visibility: hidden;

    &:before {
      font-weight: 900;
      content: "\f089";
      font-size: 0.8rem;
      visibility: visible;
      position: absolute;
      right: 8px;
      top: 7px;
    }
  }

}



.leaflet-control-container {

  .leaflet-top, .leaflet-left, .leaflet-bottom, .leaflet-right {
    margin: 10px;
    border-radius: $br;
    @include shadow;

    .leaflet-bar {
      border: 0;
      margin: 0;
      background: white;
      border-radius: 0;

      &:first-child {
        padding-top: 5px;
        border-radius: $br $br 0 0;
      }

      &:last-child {
        padding-bottom: 5px;
        border-radius: 0 0 $br $br;
      }

      a {
        border-bottom: none;
        height: 40px;
        width: 40px;
        font-size: 2rem;
        visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          color: $primary;
          background: transparent;
          border-bottom: 0;

          &.rectangle:before, &.circle:before {
            border: 0.5px solid $primary;
          }
        }

        &:focus, &:active {
          color: $primary;
          background: transparent;
          border-bottom: 0;

          &.rectangle:before, &.circle:before {
            border: 0.5px solid $primary;
          }
        }

        &:before {
          visibility: visible;
          position: absolute;
        }

        &.leaflet-control-zoom-in:before {
          content: '\f087';
          font-size: 1.6rem;
        }

        &.leaflet-control-zoom-out:before {
          content: '\f088';
          font-size: 1.6rem;
        }

        &.marker:before {
          content: '\e98c';
        }
        &.line:before {
          content: '\ebc8';
        }
        &.polygon:before {
          content: '\ebf9';
        }
        &.edit:before {
          content: '\e98b';
        }
        &.delete:before {
          content: '\e996';
        }
        &.rectangle:before, &.circle:before {
          content: '';
          border: 0.5px solid $nd;
          border-radius: 2px;
          height: 18px;
          width: 18px;
        }
        &.circle:before {
          border-radius: 50%;
        }
      }
    }
  }
}



// PAGINATION

.pagination { // ul class

  list-style-type: none;
  display: flex;

  li {
    @include ss-button;
    min-width: unset;
    width: 40px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 0;

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    &.active {
      background-color: $nl-focus;

      &:hover {
        background-color: $nl-focus;
      }
    }
  }
}




// BACK-NEXT

.ss-button {

  &.back {
    text-align: left;
    padding: 0 20px 0 15px;
    min-width: unset;
    width: 100px;

    &:before {
      font-weight: 900;
      content: "\e9eb";
      margin-right: 10px;
      font-size: 1.25rem;
    }
  }

  &.next {
    text-align: right;
    padding: 0 15px 0 20px;
    min-width: unset;
    width: 100px;

    &:after {
      font-weight: 900;
      content: "\e9ec";
      margin-left: 10px;
      font-size: 1.25rem;
    }
  }

  &.nt {
    width: 40px;
    padding: 0 10px;
    min-width: unset;
    text-align: center;

    &:before, &:after {
      margin: 0;
    }
  }
}






// SLIDERS

#slidecontainer {

  width: 100%;

  @mixin thumb {
    -webkit-appearance: none;
    border: none;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background: $nd;
    cursor: pointer;
    cursor: hand;
    box-shadow: none;

    &:hover {
      transition: 0.25s;
      background: $nd-hover;
      cursor: pointer;
      transform: scale(1.3);
    }

    &:active {
      transition: 0.25s;
      background: $nd-hover;
      cursor: pointer;
      transform: scale(1.3);
      box-shadow: 0 0 0 8px rgba(58,58,58,0.15);
    }
  }

  @mixin track {
    width: 100%;
    height: 3px;
    cursor: pointer;
    cursor: hand;
    box-shadow: none;
    background: $nl;
    border-radius: 0;
    border: none;
    display: flex;
    align-items: center;
  }

  input[type="range"].ss-slider {
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    margin: 15px 0;

    &:hover {
      &::-webkit-slider-runnable-track {
        background: $nl;
      }

      &::-ms-fill-upper {
        background: $nl;
      }
    }

    &:focus {
      outline: none;

      &::-webkit-slider-runnable-track {
        background: $nl;
      }

      &::-ms-fill-upper {
        background: $nl;
      }
    }

    //chrome

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      @include thumb;
    }

    &::-webkit-slider-runnable-track {
      @include track;
    }

    //mozilla

    &::-moz-range-thumb {
      @include thumb;
    }

    &::-moz-range-track {
      @include track;
    }

    &::-moz-range-progress {
      background: $nd;
      height: 5px;
    }

    //IE

    &::-ms-thumb {
      @include thumb;

      &:active {
        box-shadow: none;
      }
    }

    &::-ms-track {
      width: 100%;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
      @include track;
    }

    &::-ms-fill-lower {
      background: $nd;
    }

    &::-ms-fill-upper {
      background: $nl;
      border-radius: 3px;
      box-shadow: none;
    }
  }
}




// RADIOS + CHECKBOXES

.radio, .checkbox {
  opacity: 0;
  position: absolute;

  + .radio-label, + .checkbox-label {
    cursor: pointer;
    font-size: inherit;
    @include p-lg;
    display: grid;
    align-items: center;
    grid-template-columns: 20px auto;
    grid-template-rows: 1fr;
  }

  + .radio-label:before, + .radio-label:after, + .checkbox-label:before, + .checkbox-label:after {
    content: '';
    width: 15px;
    height: 15px;
    box-sizing: border-box;
    border: 1px solid transparent;
    z-index: 0;
    background-color: transparent;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  + .radio-label:before, + .radio-label:after {
    border-radius: 50%
  }

  + .checkbox-label:before, + .checkbox-label:after {
    border-radius: 1px;
  }

  + .radio-label:before, + .checkbox-label:before {
    border-color: $nd;
  }

  + .radio-label:after, + .checkbox-label:after  {
    -webkit-transform: scale(.6);
    -ms-transform: scale(.6);
    transform: scale(.6);
  }

  &:checked {
    + .radio-label:after, + .checkbox-label:after {
      background: $nd;
    }
  }
  &:disabled {
    + .radio-label:before, + .checkbox-label:before {
      border-color: $nl-focus !important;
    }

    + .radio-label, + .checkbox-label {
      color: $nl-focus;
      cursor: default;
    }

    &:checked + .radio-label:after, &:checked + .checkbox-label:after {
      background: $nl-focus !important;
    }
  }
}



// DROPDOWNS

select.dropdown {
  @include p-lg;
  border-radius: 3px;
  padding: 3px;
}



// TEXT INPUT

.form-prompt {
  margin-bottom: 0;
}

.text-input {
  margin: 1rem 0;

  label {
    display: block;
    margin: 0 0 3px 3px;
    @include p-lg;
  }

  input {
    padding: 5px;
    border-radius: 3px;
    border: 1px solid $md-lt-grey;

    &:focus, &:active {
      outline: 0;
    }
  }

  i {
    margin-left: -22px;
    position: absolute;
    margin-top: 4px;
    font-size: 18px;
  }

  ::-webkit-input-placeholder {
    color: $dk-grey;
    font-family: $OpenSans;
  }

  .success {
    border: 1px solid $success;

    &::-webkit-input-placeholder {
      color: $success;
    }
    +i {
      color: $success;
    }
  }

  .warning {
    border: 1px solid $warning;

    &::-webkit-input-placeholder {
      color: $warning;
    }
    +i {
      color: $warning;
    }
  }

  .error {
    border: 1px solid $error;

    &::-webkit-input-placeholder {
      color: $error;
    }
    +i {
      color: $error;
    }
  }
}



// TEXTAREA

.text-area {

  label {
    display: block;
    margin: 0 0 3px 3px;
    @include p-lg;
  }

  textarea {
    @include p-lg;
    padding: 0 5px;
    color: $md-dk-grey;
    border: 1px solid $md-lt-grey;
    border-radius: 3px;
    max-width: 100%;

    &:focus, &:active {
      outline: 0;
    }
  }
}



// SUBMIT

.submit-btn {
  input {
    @include ss-button;
  }
}