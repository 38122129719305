@use './old-design-system';
@use '~@strategies/tokens/color';

$primary-theme-large: old-design-system.$primary;
$primary-theme: #5b1cf4;
$primary-theme-hover: old-design-system.$primary-hover;
$nd-theme: old-design-system.$md-dk-grey;
$nd-theme-hover: old-design-system.$nd-hover;
$input-color: #333333;
$background-active: #EDEBFF;
$active-color: color.$blue;

@mixin user-photo {
  $d: 35px;
  flex: 0 1 $d;

  width: $d;
  height: $d;

  text-align: center;
  border: 3px solid #4E28C4;
  border-radius: 50%;
  overflow: hidden;
  background: #4E28C4;

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -50%;
    right: -50%;

    min-width: 105%;
    height: 105%;
    margin: auto;
  }

  .big-char {
    font-size: 25px;
    margin-top: -2px;
    color: white;
  }
}