@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';

.ClampedBar {
    position: relative;
    display: block !important;

    .StackedBar__value {
        color: $success;
    }

    &.out-of-range {
        .StackedBar__value {
            color: $error;
        }
    }

    &.presentationMode {
        .StackedBar__value {
            color: black !important;
        }
    }

    .clamp .Bar {
        height: 10px;
        width: 60%;
        margin: auto;

        background: #aaaaaa;
    }

    .match-target {
        position: absolute;
        bottom: -95%;
        right: 0;

        padding: 5px 5px;

        color: color.$black;
        font-size: font.$size--xsmall;

        background: color.$white;
        border-radius: 5px;
        border-width: 0;
        z-index: 1004;
        box-shadow: layout.$box-shadow;

        &:hover {
            cursor: pointer;
            background-color: color.$blue;
            color: color.$white;
        }
    }

    .reset-shape {
        position: absolute;
        bottom: -100%;
        right: 0;

        padding: 5px 8px;

        color: color.$black;
        font-size: font.$size--xsmall;

        background: color.$white;
        border-radius: 5px;
        border-width: 0;
        z-index: 1004;
        box-shadow: layout.$box-shadow;

        &:hover {
            cursor: pointer;
            background-color: color.$blue;
            color: color.$white;
        }
    }

    .StackedBar .Bar {
        display: flex;
        background: none;
        overflow: visible;
        white-space: nowrap;
    }
}
