.ReactLogin {
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  div{
    align-self: center;
  }
  img{
    width: 300px;
  }
  h1{
    font-family: $Korolev;
    font-weight: 700;
  }
  p{
    font-family: $Korolev;
    font-weight: 200;
  }

  .landing {
    .screens {
      max-height: 100vh;
      max-width:  80vw;
    }
    // center or top left
    img:nth-of-type(1){
      top: 10vh;
      bottom: auto;
      width: 40vw;
      height: auto;
      left: auto;
      right: 10vw;
    }
    // bottom left
    img:nth-of-type(2){
      top: 40vh;
      bottom: 20vh;
      right:34vw;
      left: auto;
      width: auto;
      height: 45vh;

    }
    // bottm right
    img:nth-of-type(3){
      right: 1vw;
      left: auto;
      bottom: 4vh;
      top: 35vh;
      width: 26vw;
    }
    // top right
    img:nth-of-type(4){
      top: 1vh;
      bottom: auto;
      right: 1vh;
      left: auto;
      width: 34vw;
    }


  }

}