@use "src/theme";
@use '~@strategies/tokens/font';

.ActiveUsers {
  position: absolute;
  top: 70px;
  left: 200px;

  width: 300px;
  padding: 4px 18px;

  background: theme.$background-active;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  //text-align: center;


  .header {
    display: flex;
    align-items: center;
    //justify-content: center;

    svg {
      width: 24px;
      height: 24px;
    }
    .panel-title {
      font-size: 18px;
      padding: 5px 10px;
      flex:1;
    }
  }

  &.errored {
    border-color: rgba(red, .3);

    h5 {
      color: red !important;
    }
  }

  ul {
    padding: 0;
    list-style: none;
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  li {
    background-color: rgba(255, 255, 255, 0.5);
    height: 40px;
    border-radius: 0.25em;
  }

  .option-toggle {
    display: flex;
    align-items: center;

    margin: 1em;
    .label {
      padding: 0 0.75em;
    }
  }

  .active-user {
    display: flex;
    width: 100%;

    & + .active-user {
      margin-top: 10px;
    }

    &--away {
      .active-user__info p {
        color: #aaa;
      }

      .UserPhoto {
        border-color: #aaa;
      }
    }

    .UserPhoto {
      @include theme.user-photo;
      margin-left: 10px;
      margin-top: 3px;
    }

    &__info {
      flex: 1 1 auto;
      margin-left: 1em;

      text-align: left;
      padding-top: 0.7em;
      font-size: 0.8em;
    }

    p {
      display: inline-block;
      position: relative;
      top: 10px;

      margin: auto;
      color: #4E28C4;
      font-weight: 600;
      font-size: font.$size--regular;
      text-transform: uppercase;
    }
  }

  .buttons {
    margin-bottom: 1em;
    text-align: center;
    button.secondary {
    }
  }
}
