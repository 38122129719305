@use '~@strategies/tokens/color';


.ProjectSettings {

  .Modal--window {
    min-width: 800px;
  }
  main {
    max-height: 60vh;
    overflow-y: auto;
  }
  .content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: visible;


    .project-info{
      display: inline-block;
      .section-tooltip{
        display: flex;
        .tool-tip{
          left: -210px;
        }

        input{
          height: 35px;
          width: 325px;
        }
      }


    }
    .section-title{
      font-weight: 500;
      color:color.$black;
      font-size: 18px;
      line-height: 1.2;
      margin-top: 30px;
    }
    select{
      height: 40px;
      width: 330px;
    }

    .section-block{
      display: flex;
      .ReactForm__Input{
        margin-right: 10px;
      }
      .ReactForm__Input-input{
        height: 40px;
        width: 150px;
      }
      &.secondary{
        margin-top: 10px;
      }

    }

  }

}
