.Autocomplete{
  height: 100%;
  input {
    border: 1px solid #999;
    padding: 0.5rem;
    width: 100%;
    height: 100%;
  }

  .no-suggestions {
    color: #999;
    padding: 0.5rem;
  }

  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    z-index: 9999;
    position: fixed;
    background-color: #ffffff;
  }

  .suggestions li {
    margin: 0;
    padding: 0.5rem;
    cursor: pointer;
  }

  .suggestion-active {
    background-color: #008f68;
    color: #fae042;
    font-weight: 700;
  }

  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }
}
