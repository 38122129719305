.Arrow{
  .clickArea{
    stroke: rgba(blue,0.05);
    stroke-width: 12px;
  }
  .arrow{
    stroke-width: 2px;
    stroke: black;
    pointer-events: initial;
    &.editMode{
      stroke: blue;
    }
    &.hide{
      display: none;
    }
  }
  .arrowOrigin, .arrowTarget{
    pointer-events: initial;
  }
  &.dragging{
    .arrowOrigin, .arrowTarget, .arrow, .clickArea{
      pointer-events: none;
    }
  }
}


.arrowhead-unselected{
  fill: black;
}

.arrowhead-selected{
  fill: blue;
}
