@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';

.InfoModal {

  .Modal--window {
    min-width: 800px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: visible;

    .section-title{
      font-weight: 500;
      color: color.$black;
      font-size: font.$size--regular;
      text-transform: uppercase;
    }
    .ReactForm__Description {
      display: inline-block;
      margin-top: 10px;
    }
  }



}
