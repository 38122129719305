@use '~@strategies/tokens/font';

.TooltipIcon {

  transform: translateY(- font.$line-height) translateX(- font.$size--regular);

  svg {
    width: font.$size--small;
    height: font.$size--small;
    position: absolute;
    vertical-align: center;
  }

}