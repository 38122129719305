@use 'sass:math';
@use '@strategies/tokens/animation';
@use '@strategies/tokens/color';
@use '@strategies/tokens/font';
@use '@strategies/tokens/layout';
@import "../../theme";

.ExportModal {

  .Modal--window {
    min-width: 800px;
  }

  .content {
    margin-bottom: layout.$padding--small;
    .content-header {
      font-weight: font.$weight--medium;
      color: color.$black;
      font-size: font.$size--xlarge;
    }
  }

  input {
    width: 1.4em;
    height: font.$line-height--large;
  }
  .ReactForm__Radio-radio{
    margin-top: 15px;
  }

  .ReactForm__Description {
    display: inline-block;
    margin: 20px 0 0;
    font-size: 12px;

  }

  .ReactForm__Label {
    width: 25px;
  }

  label {
    color: color.$black;
    font-size: font.$size--small;
    line-height: font.$line-height;

  }

  .post-state {
    font-weight: 500;
    color: color.$black;
    font-size: font.$size--xlarge;
    line-height: font.$line-height--large;
    text-align: center;
    .message {
      padding: layout.$padding 0;
    }
  }

}
