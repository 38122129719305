@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';

.LoadImageDialog {
    .Modal--window {
        min-width: 500px;
    }

    label {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
    }

    error {
        color: color.$red;
    }

    input {
        display: none;
    }

    .disabled {
        opacity: 50%;
    }

    .image {
        display: flex;
        align-items: center;

        p {
            text-decoration: underline;
        }

        .IconButton {
            cursor: pointer;

            svg {
                stroke: color.$red;
                transition: stroke animation.$speed--fast;
            }

            &:hover svg {
                stroke: color.$red__alt;
            }
        }
    }
}
