@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.IntroModal {

  &.action-pending {
    .button-like, button {
      opacity: 50%;
      pointer-events: none;
      * {
        pointer-events: none;//needed to override Modal wildcard rule
      }
    }
  }

  .Modal {
    &--gutter {
      cursor: initial;
    }

    &--window {
      header {
        display: none;
      }
    }
  }

  .logo {
    padding: layout.$padding;

    text-align: center;
  }

  img {
    height: 2.5rem;
  }

  p {
    padding: layout.$padding 0;

    font-size: font.$size--large;
    line-height: font.$line-height--large;
  }

  .buttons {
    padding: layout.$padding 0;

    border-top: 5px solid color.$blue--light;
    border-bottom: 5px solid color.$blue--light;
  }

  .whats-new {
    p {
      padding: 0;
      margin: layout.$padding 0;
    }
  }

  h2 {
    margin: 0;
    font-size: font.$size--xlarge;
    font-weight: font.$weight--medium;

    svg {
      display: inline-block;
      margin-right: layout.$padding--smallest;
    }

    button {
      display: inline-flex;
      align-items: center;

      font-size: inherit;
      font-weight: inherit;

      background: none;
      border: none;
      cursor: pointer;

      &:hover {
        opacity: .75;
      }
    }
  }

  h3 {
    margin: layout.$padding 0 0;
    font-size: font.$size--regular;
    font-weight: font.$weight--medium;
  }

  ul {
    display: flex;
    gap: layout.$padding;

    padding: 0;
    list-style: none;
  }

  li {
    flex: 1 1;

    margin: auto;

    svg {
      display: block;

      color: color.$black;
      margin: auto auto layout.$padding--small;
    }

    button, a, label {
      display: flex;
      align-items: center;

      width: 100%;
      min-height: 125px;

      color: color.$blue;
      font-size: font.$size--large;
      line-height: font.$line-height--large;

      background: color.$blue--light;
      border-radius: layout.$border-radius;
      border: none;
      box-shadow: layout.$box-shadow;
      cursor: pointer;
      transition: none;

      &:hover {
        color: color.$white;

        background: color.$blue;
      }

      div {
        margin: auto;
        text-align: center;
      }
    }
  }

  input {
    display: none;
  }

}
