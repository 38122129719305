@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';
@use '~@strategies/tokens/color';
.GenerateFromTable {

  .Modal--window {
    min-width: 960px;
  }
  header {
    padding-bottom: layout.$padding--smallest;
  }
    main {
      padding-top: layout.$padding--smallest;
    }

    p {
      margin: 10px 0 0;
      color: color.$grey;

      font-size: font.$size--regular;

      line-height: font.$line-height--large;

    }

}

