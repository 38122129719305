@use '~@strategies/tokens/color';
@use '~@strategies/tokens/layout';
@use 'sass:math';

.DashboardView {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 2;
  justify-self: end;
  z-index: 102;

  width: calc(100% - #{layout.$padding--small});
  margin: layout.$padding--small;
  margin-left: 0;

  pointer-events: all;

  main {
    padding: layout.$padding--small;
  }

  .BarChart {
    display: flex;

    & + .BarChart {
      margin-top: layout.$padding;
    }

    & > label {
      flex: 1 0 20%;
    }

    & > .BarChart__graphic {
      position: relative;
      flex: 1 0 80%;

      .BarChart__graphic__label {
        padding: math.div(layout.$padding--smallest,2);
        background-color: color.$white;
        background-clip: padding-box;
        border-radius: layout.$border-radius--small;
      }
    }

    &__value {
      position: relative;
      z-index: 2;

      padding: 5px;
      background: rgba(255, 255, 255, .3);
    }
  }

  .record {
    margin-top: 16px;

    h5 {
      margin-bottom: 8px;
    }

    .StackedBar__value {
      color: $success;
    }

    &.out-of-range {
      .square-gross,
      .StackedBar__value {
        color: $error;
      }
    }

    &.presentationMode {
      .square-gross {
        color: #000000;
      }
    }
  }

  .record__info {
    display: flex;
    width: 100%;
    margin-top: 8px;


    & > * {
      flex: 1 0 60%;
    }
  }

  .record_name_info {
    margin: 0 0 8px 0;
    display: flex;

    .name__info {
      flex: 1 0 45%;
      display: flex;

      .name__info__label {
        flex: 1 0 44% !important;
      }

      .name__info__value {
        flex: 1 0 56%;

        label {
          padding: math.div(layout.$padding--smallest,2);
          background-color: color.$white;
          background-clip: padding-box;
          border-radius: layout.$border-radius--small;
        }

      }

    }

    .efficiency__info {
      flex: 1 0 55%;
      display: flex;

      .efficiency__info__label {
        flex: 1 0 45% !important;
      }

      .efficiency__info__value {
        flex: 1 0 55%;

        label {
          padding: math.div(layout.$padding--smallest,2);
          background-color: color.$white;
          background-clip: padding-box;
          border-radius: layout.$border-radius--small;
        }
      }
    }


  }
}

.SummaryInnerPanel {
  .clamp {
    display: none;
  }

  .summary-group {
    .header {
      display: flex;
      justify-content: space-between;

      h5 {
        margin: 0;
      }

      button {
        display: inline-block;
        background: none;
        border: none;
        outline: none;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .BarChart {
      margin-top: 8px;
    }

    + .summary-group {
      margin-top: layout.$padding;
    }
  }
}

.DepartmentView {
  .FillBar {
    label {
      display: block;
      flex: 0;
      width: 100%;
    }
  }
}
