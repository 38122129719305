@use '~@strategies/tokens/color';
@use '~@strategies/tokens/layout';


.Toolbar {
    width: min-content;
    margin: layout.$padding--small;

    background: color.$white;
    border-radius: layout.$border-radius--small;
    box-shadow: layout.$box-shadow;
    overflow: hidden;
    pointer-events: all;

    .IconButton {
        display: block;
        border-radius: 0;
    }
}
