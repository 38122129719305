@use '~@strategies/tokens/font';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/layout';

.Tooltip {
  background-color: color.$white;
  padding: layout.$padding--smallest layout.$padding--smallest layout.$padding--smallest layout.$padding;
  position: absolute;
  z-index: 999;
  width: 150px;
  height: auto;
  border-radius: layout.$border-radius--small;
  -webkit-box-shadow: layout.$box-shadow;
  -moz-box-shadow: layout.$box-shadow;
  box-shadow: layout.$box-shadow;

  p {
    margin: 0;
    font-size: font.$size--regular;
    line-height: font.$line-height--large;
  }
}
