@use 'sass:math';
@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';
@use '../../theme';

@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(5px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.MenuBar {
    margin: layout.$padding--small;
    pointer-events: all;

    .TopRibbon--ribbon-collapsible .IconButton {
        opacity: 0;
    }

    &:not(.collapsed) {
        .TopRibbon--ribbon-collapsible .IconButton {
            animation: fadeUp animation.$speed--slow forwards;

            $_length: 5;
            @for $i from 1 to $_length {
                &:nth-of-type(#{$_length - $i}) {
                    animation-delay: $i * (.01s + math.div(animation.$speed, $_length));
                }
            }
        }
    }

    .file-input {
        display: flex;

        margin: 0 layout.$padding--small;
        border-radius: 20px;
        overflow: hidden;

        label {
            flex: 1 0 max-content;
            display: flex;
            align-items: center;

            padding: 0 layout.$padding;

            color: color.$grey;
            font-size: font.$size--xsmall;

            background: color.$blue--lightest;
        }

        input {
            flex: 1 0 auto;

            width: 175px;
            height: 100%;
            padding: layout.$padding--smallest layout.$padding--small;

            color: color.$black;
            font-size: font.$size--small;
            font-weight: font.$weight--medium;
            text-overflow: ellipsis;
            white-space: nowrap;

            background: color.$blue--light;
            border: 0;
            outline: none;
            overflow: hidden;
        }
    }

  .UserPhoto {
    @include theme.user-photo;
  }

  .counter {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 18px;
    height: 18px;
    background: theme.$active-color;
    border-radius: 50%;
    font-size: 12px;
    color:white;
  }
}
