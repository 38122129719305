@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';
@use '~@strategies/tokens/color';
.ExportPreview {
    svg.export-preview {
        border: 1px solid #999;
        width: 100%;
        height: 100%;
    }
    .legend-toggle {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .ReactForm__CheckboxSlider {
            display: inline-block;
            margin-right: 10px;
            cursor: pointer;
        }
    }
    .Panel:not(.active) {
        height: auto;//override for default fixed height value in @strategies/ui Panel
    }
}

