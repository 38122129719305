@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';

.InnerPanel {
    background: color.$blue--lightest !important;
    border-radius: layout.$border-radius--small !important;
    box-shadow: none !important;

    & + & {
        margin-top: layout.$padding;
    }

    .Panel--header__left {
        h2 {
            font-size: font.$size--regular !important;
        }

        .IconButton {
            display: none;
        }
    }

    &:not(.active) {
        width: 100% !important;

        h2, .Panel--header__right {
            display: block !important;
        }
    }

    .IconButton {
        background: none !important;
    }

    main {
        padding: layout.$padding--small !important;

        font-size: font.$size--small !important;
    }

    &.DimensionsInnerPanel {
        overflow: visible;

        main {
            overflow: visible;
        }

        .ReactForm__Group {
            label, input, select {
                font-size: font.$size--small;
            }
        }

        .efficiency-factor {
            flex: 0 1 14em;
        }

        .SelectCategory {
            background: color.$white;
            border: 2px solid color.$grey;
            border-radius: layout.$border-radius;

            &__control,
            &__input-container {
                background: none;
            }
        }

        .with-category {
            .ReactForm__Label {
                flex: 1 0 auto;

            }

            .ReactForm__Input {
                flex: 0 1 10em;
            }
        }
    }

    &.ElementsInnerPanel {
        .Selector {
            display: flex;
            align-items: center;

            + .Selector {
                margin-top: layout.$padding--small;
            }
        }

        span {
            flex: 1 0;
        }

        .Button {
            flex: 0 1 max-content;

            font-size: font.$size--small;
        }
    }

    &.SummaryInnerPanel, &.CostInnerPanel {
        .presentation-mode-toggle {
            display: flex;
            align-items: center;

            margin-top: layout.$padding;

            .ReactForm__CheckboxSlider {
                display: inline-block;

                margin-right: layout.$padding--small;

                cursor: pointer;
            }
        }
    }

}

