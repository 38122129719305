@use 'sass:math';
@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';
.pick-colors {
 .react-colorful {
   width: auto;
   padding: layout.$padding--small layout.$padding--smallest layout.$padding 0 ;
   border-radius: layout.$border-radius--small;
 }
  .react-colorful__saturation {
    margin: layout.$padding--small 0 layout.$padding--small 0;
    border-radius: layout.$border-radius;
    border-bottom: none;
  }
  .react-colorful__hue {
    height: font.$size__base; // NOTE: needs to be in pixel value
    padding: 0 0 layout.$padding--small 0;
    border-radius: layout.$border-radius;
  }
  .react-colorful__saturation-pointer,.react-colorful__hue-pointer, {
    width: font.$size__base; // NOTE: needs to be in pixel value
    height: font.$size__base; // NOTE: needs to be in pixel value
  }

  .ReactForm__Input {
    width: auto;
    padding: 0 layout.$padding--smallest layout.$padding 0 ;

  }
}