@use '~@strategies/tokens/color';
@use '~@strategies/tokens/layout';
@use '~@strategies/tokens/font';
@use 'sass:math';

$bar-height: 20px;

.StackedBar {

  & > .BarChart__graphic {
    flex: 1 0 60% !important;
    background-color: color.$white;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .StackedBar__value {
    flex: 1 0 20%;
    text-align: right;
    font-size: font.$size--xsmall;
    vertical-align: middle;
    background-color: color.$white;
    padding: math.div($bar-height,5) 0 math.div($bar-height,5) 0;
    border-radius: 0 layout.$border-radius--small layout.$border-radius--small 0;
  }

  .Bar {
    height: $bar-height;
    background-color: color.$white;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
