@use '~@strategies/tokens/layout';
@use '~@strategies/tokens/color';

.Bar {
    height: 100%;
    overflow: hidden;
    border-bottom-right-radius: layout.$border-radius--small;
    border-top-right-radius: layout.$border-radius--small;

    &__graphic {

        height: 100%;
        padding: 0;
        margin: 0;

        background: color.$white;
        transform: translateZ(0);

        border-bottom-right-radius: 0;
        border-top-right-radius: 0;

        &:only-child {
            border-bottom-right-radius: layout.$border-radius--small;
            border-top-right-radius: layout.$border-radius--small;
        }

    }

    &__graphic + &__graphic {
        border-bottom-right-radius: layout.$border-radius--small;
        border-top-right-radius: layout.$border-radius--small;

    }


}
