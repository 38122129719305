@use '~@strategies/tokens/color';
@use '~@strategies/tokens/layout';
@use '~@strategies/tokens/font';
@use 'sass:math';

$bar-height: 12px;

.FillBar {
  display: block !important;

  &:first-of-type {
    margin-top: 24px !important;
  }

  color: color.$black;

  label {
    display: block;
    margin-bottom: 5px;
    font-size: font.$size--xsmall;

  }

  .Bar {
    background: #dddddd;
  }

  & > .BarChart__graphic {
    display: flex;
    flex: 0 0;

    & > .Bar {
      flex: 1 0 80% !important;
      height: $bar-height;
    }
  }

  .FillBar__value {
    font-size: font.$size--xsmall;
    flex: 1 0 20%;
    text-align: right;

    background-color: color.$white;
    border-radius: layout.$border-radius--small 0 0 layout.$border-radius--small;

    padding: math.div($bar-height,10) 0 0 0;
  }

}
