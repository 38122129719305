@use 'sass:math';
@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';
@import "src/theme";

.LabelColor {
  margin-top: layout.$padding;
  text-align: center;
  padding: math.div(layout.$padding--small,2);
  font-size: font.$size--regular;
  font-weight: font.$weight--medium;
  //color: color.$white;
  opacity: 1;
  transition: color 0.25s, opacity 0.25s;
  vertical-align: middle;
  max-width: 230px;
  border-radius: layout.$border-radius--small;
  text-transform: uppercase;
  &:hover {
    cursor: default;
  }
}

.EditCategoryHandle {
  width: 5px;
  height: 60px;
  padding: 0 8px 5px 0;
  margin: 0 0 0 0;
  border-radius: layout.$border-radius--small;

  &:hover {
    cursor: pointer;
  }
}