@use '~@strategies/tokens/color';

.EditCategory {
  min-height: 400px;
  .ReactForm__Group:nth-of-type(2) {
    .ReactForm__Input {
      max-width: 40vw;
    }

  }
  .ReactForm__Group:nth-of-type(3) {
    .ReactForm__Input {
      width: 100%;
    }
  }
  .delete {
    float: left;
    background-color: color.$white;
  }

  .pick-colors-hex {
    position: absolute;
    top: 160px;
    max-width: 40vw;
    height: 200px;
    margin: 0;
    padding: 0;
  }


}

