@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';
@use '~@strategies/tokens/color';

@import "src/theme";

.Table {

  &.full {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;

  margin: auto layout.$padding--small layout.$padding--small auto;

  &.active {
    margin: layout.$padding--small;
  }

  //z-index: 102;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: visible;
  justify-self: end;
  pointer-events: all;

  transition: all 0.35s ease-in-out;
  max-height: 10000px;
  max-width: 10000px;

  .category_edit_handle {
    &:hover {
      span {
        border: 2px solid $active-color;
      }

    }
  }

  .drawn-size {
    background-color: #F2F5FA;
  }

  .target-size {
    background-color: #E2E7EE;
  }

  header {
    .Panel--header__right {
        padding-right: 3px;
    }
  }

  main {
  }


  table {
    border-collapse: collapse;
    border-spacing: 0;

    table-layout: fixed;
    width: 100%;
    margin-top: 10px;

    //&.full {
    //  grid-column-start: 1;
    //  grid-column-end: 6;
    //}
  }

  th, td, td > input {

    font-weight: font.$weight--medium;
    font-family: "Inter-Medium", "Inter", sans-serif;
    color: #050038;
    font-size: font.$size--xsmall;
    line-height: font.$line-height;
    text-align: center;



  }
  td > input[type=text]:focus {
    outline-width: thin;
    outline-color: color.$blue--lightest;
    box-shadow: none;
  }


  th {
    font-weight: 500;
    cursor: pointer;

    > svg {
      margin-left: 3px;
    }
  }

  td.category {
    //min-width: 8em;
    //max-width: 20em;
  }

  td.grey {
    background-color: #F2F5FA;
  }

  td.d-grey {
    background-color: #E2E7EE;
  }


  td, th {
    padding: 0.5rem 1rem;
    white-space: nowrap;
    //display: flex;
    align-items: center;
    //flex: 0 0 auto;
    width: 90px;
  }

  .t-name {
    min-width: 360px;
    flex: 1 1 auto;
  }

  .t-name span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .trow {
    background-color: white;
    border-top: 1px solid #ccc;

    //background-color:pink;

    &.selected + tr {//if this row is immediately adjacent to one that is selected, don't show the top border because the selected element's bottom border should be shown instead
      border-top: none;
    }

    &.selected {
      background-color: $background-active;
      border-top: 1px solid $active-color;
      border-bottom: 1px solid $active-color;

    }

  }

  thead th {
    button {
      background: none;
      border: none;
    }
  }

  thead {
    tr {
      &.group-row {
        border-top: 1px solid #7D7A96;
        border-bottom: 1px solid #7D7A96;

        th {
        }
      }

      &.title-row {
        position: sticky;
        inset-block-start: 0; /* "top" */
        z-index: 2;
        border-bottom: 1px solid #7D7A96;
        background-color: white;
        box-shadow: 0 4px 4px -2px #2323230f;//not the default box shadow, just a hint that content can flow below this

        //th {
        //  border: 1px solid rgba(200, 200, 200, 0.2);
        //}
      }
    }

  }

  tbody td {
    padding: 0;

    input {
      width: 95%;//give some breathing room for focus styling
      min-width: 60px;
      padding: 0 7px 0 7px;
      border: none;
      background: transparent;
    }
  }
}
